<template>
  <v-card>
    <v-toolbar dense flat color="primary">
      <v-toolbar-title class="white--text">Select cases</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="caseSelectionDialog = false">
        <v-icon class="white--text">mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="mt-4">
      <v-row>
        <v-col cols="12" sm="3">
          <!-- Treeview for folders -->
          <v-treeview
            v-model="selectedFolders"
            :items="folders"
            item-key="uid"
            item-text="name"
            item-children="cases"
            selectable
            selected-color="primary"
            class="text-left elevation-1 shaped"
            open-on-click
          >
            <template v-slot:prepend="{ item }">
              <v-icon color="amber darken-2">
                {{ item.children ? 'mdi-folder-open' : 'mdi-folder' }}
              </v-icon>
            </template>
          </v-treeview>
        </v-col>
        <v-col cols="12" sm="9">
          <!-- Data table for cases -->
          <v-data-table
            v-model="selectedCases"
            :headers="caseHeaders"
            :items="filteredCases"
            checkbox-color="primary"
            item-key="uid"
            flat
            show-select
            class="elevation-1 shaped"
          ></v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed color="primary" @click="confirmSelection">Select cases</v-btn>
      <v-btn depressed @click="$emit('cancel')">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    existingSelectedCases: { 
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentPage: 1,
      selectedFolders: [],
      selectedCases: [],
      flatCaseList: [],
      caseHeaders: [
        { text: 'Case', align: 'start', value: 'name' },
        { text: 'State', value: 'state' },
      ],
    };
  },
  computed: {
    ...mapState('case', ['items']),
    filteredCases() {
      if (!this.selectedFolders.length) {
        return this.cases;
      }
      return this.cases.filter((c) => this.selectedFolders.includes(c.parentUid));
    },
    cases() {
      return this.items.map((item) => item);
    },
    selectedProject() {
      return this.$route.params.key;
    },
    folders: {
      get() {
        return this.$store.state.folder.folders;
      },
      set(value) {
        this.$store.commit('folder/SET_FOLDERS', value);
      },
    },
  },
  methods: {
    hasChildren(item) {
      return item.children && item.children.length > 0;
    },
    confirmSelection() {
      this.$emit(
        'cases-selected',
        this.selectedCases.map((c) => c.uid)
      );
    },
    async getProjectFolders() {
      await this.$store.dispatch('folder/getProjectFolders', this.$route.params.key);
    },
    // Fetches test cases within the selected folder
    getFolderCases() {
      this.$store.dispatch('case/getFolderCases', {
        folderId: this.selectedFolder,
        projectKey: this.$route.params.key,
        page: this.currentPage,
        limit: 30,
      });
    },
    async getProjectCases() {
      await this.$store.dispatch('case/getProjectCases', {
        projectKey: this.$route.params.key,
        page: this.currentPage,
        limit: 30,
      });
    },
    updateSelectedCases() {
      this.selectedCases = [];
      this.selectedFolders.forEach((folder) => {
        this.cases.forEach((currentCase) => {
          if (currentCase.parentUid === folder) {
            if (!this.selectedCases.includes(currentCase.uid)) {
              this.selectedCases.push(currentCase);
            }
          }
        });
      });
    },
  },
  watch: {
    selectedProject() {
      this.getProjectFolders();
    },
    selectedFolders() {
      this.updateSelectedCases();
    },
    cases() {
      this.selectedCases = this.cases.filter((_case) => this.existingSelectedCases.includes(_case.uid));
    },
  },
  created() {
    if (this.selectedProject) {
      this.getProjectFolders();
      this.getProjectCases();
    }
  }
};
</script>

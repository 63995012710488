<template>
  <v-container id="case-index-container" class="d-flex flex-column justify-start px-0 font-inter align-self-stretch"
    fluid>
    <RunHeader :isDuplicate="RunViewType == 'Duplicate'" :title="$t('testRuns')" :actionText="$t('testruns.createTestRun')"
      :active_label="$t('testruns.ongoing')" :archived_label="$t('testruns.archived')" :btn_show="true" :filter="filter"
      @update-filter="updateFilter" :activeItemCount="activeItemCount" :archivedItemCount="archivedItemCount" />
    <template>
      <v-container class="d-flex flex-column pa-6 elevation-1 white rounded-lg align-self-stretch" fluid>
        <template v-if="items.length > 0">
          <v-row class="align-center">
            <v-col cols="6" sm="6">
              <div class="d-flex flex-row justify-start align-center">
                <v-responsive class="ma-0" max-width="344">
                  <v-text-field :loading="loading" v-model="searchFilter" class="rounded-lg pa-0"
                    prepend-inner-icon="mdi-magnify" :placeholder="$t('searchByName')" dense filled
                    hide-details></v-text-field>
                </v-responsive>
                <RunFilter @applyFilters="applyFilters" />
              </div>
            </v-col>
            <v-col cols="6" sm="6" class="d-flex justify-end">
              <v-menu left :close-on-content-click="false" :nudge-bottom="4" offset-y min-width="191">
                <template v-slot:activator="{ on }">
                  <v-btn class="pa-0 rounded-lg" height="40" min-width="40" elevation="0" v-on="on">
                    <v-icon size="16px">
                      mdi-cog-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-list width="191" class="d-flex flex-column justify-space-between rounded-xl">
                  <v-list-item class="font-inter custom-text-14 custom-menu-item my-2">{{ $t('columns') }}</v-list-item>
                  <v-list-item v-for="(header, index) in filteredMenuHeaders" class="custom-menu-item" :key="index">
                    <v-checkbox v-model="header.isSelected" :value="header.isSelected" :true-value="true"
                      :false-value="false" off-icon="mdi-checkbox-blank" class="ma-0 pa-0" color="blue" :size="16"
                      hide-details>
                      <template v-slot:label>
                        <div class="custom-color-0c111d font-inter custom-text-14">{{ header.text }}</div>
                      </template>
                    </v-checkbox>
                  </v-list-item>
                  <v-list-item class="custom-menu-item my-2" @click="handleColumnReset()">
                    <div class="custom-text-12 font-weight-semibold font-inter custom-color-d0d5dd"
                      :class="{ 'blue--text font-weight-semibold ': isColumnFilter }">
                      {{ $t('Restore default') }}
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="fill-height">
            <v-col cols="12" class="fill-height pt-1">
              <run-table :filteredHeaders="filteredHeaders" :filteredItems="filteredRuns" :itemKey="itemKey"
                :rowClass="rowClass" :clearSelection="clearSelection" @edit-item="editItem" @select-row="handleRowClick"
                @select-item="setselected" @archive-item="confirmArchiveRun" @unarchive-item="confirmUnArchiveRun"
                @delete-item="confirmDeleteRun" />
            </v-col>
          </v-row>
          <v-row class="fill-height" v-if="isSelected">
            <v-col cols="12">
              <v-flex class="mt-6 d-sm-flex justify-end">

                <v-btn dark large color="blue darken-3"
                      class="text-capitalize font-weight-bold white--text mr-4 mt-2"
                      @click="onClickDuplicate"
                      :disabled="!hasOneSelectedItem"
                      v-if="isDuplicateRunViewType"
                      >
                      {{ $t('duplicate') }}
                    </v-btn>

                <v-menu top offset-y :nudge-top="4" v-model="menuOpen">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" dark large color="blue darken-3"
                      class="text-capitalize font-weight-bold white--text mr-4 mt-2"
                      :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'">
                      {{ $t('testruns.create_testrun.actions') }}<v-icon>{{ menuOpen ? 'mdi-chevron-up' :
                        'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense class="text-left">
                    <v-list-item v-if="filter == 'ongoing'" @click="handleConfirmDialog('multi_archive')">
                      <v-icon class="pr-2">mdi-archive-arrow-down-outline</v-icon>{{ $t('testruns.archive') }}
                    </v-list-item>
                    <v-list-item v-else-if="filter == 'archived'" @click="handleConfirmDialog('multi_unarchive')">
                      <v-icon class="pr-2">mdi-archive-arrow-up-outline</v-icon>{{ $t('testruns.unarchive') }}
                    </v-list-item>
                    <v-list-item @click="handleConfirmDialog('multi_milestone')">
                      <v-icon class="pr-2">mdi-flag-outline</v-icon>{{ $t('testruns.addToMilestone') }}
                    </v-list-item>
                    <v-list-item @click="handleConfirmDialog('multi_testplan')">
                      <v-icon class="pr-2">mdi-clipboard-check-outline</v-icon>{{ $t('testruns.addToTestPlans') }}
                    </v-list-item>
                    <v-list-item class="red--text" @click="handleConfirmDialog('multi_delete')">
                      <v-icon class="pr-2" color="red">mdi-trash-can-outline</v-icon>{{ $t('testruns.delete') }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-col>
          </v-row>
          <v-dialog v-model="showFormDialog" max-width="50%">
            <RunForm :existingRun="selectedRun" :is-loading="savingInProgress" @update="updateTestRun"
              @close="showFormDialog = false" />
          </v-dialog>
        </template>
        <template v-else>
          <v-row class="py-sm-16">
            <v-col cols="12" class="d-flex justify-center">
              <v-img :src="require('@/assets/png/auth-banner.png')" alt="Table empty state" width="100%"
                max-width="438" />
            </v-col>
            <v-col cols="12">
              <div class="d-flex flex-column align-center">
                <p class="ma-0 font-weight-bold text-h6 text-sm-h5">{{ $t('testruns.empty_state.title') }}</p>
                <p class="mb-0 mt-3">{{ $t('testruns.empty_state.description_part1') }}</p>
                <p class="ma-0">
                  {{ $t('testruns.empty_state.description_part2') }}
                </p>
                <p class="ma-0">
                  {{ $t('testruns.empty_state.description_part3') }}
                </p>
              </div>
              <v-flex class="mt-6">
                <v-btn dark large color="blue" class="text-capitalize font-weight-bold" :to="{ name: 'TestRunCreate' }">
                  {{ $t('testruns.createTestRun') }} <v-icon class="ml-1" size="16">mdi-plus</v-icon>
                </v-btn>
              </v-flex>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </template>
    <RunDiscardDialog :title="ConfirmDialog_Title" :content="ConfirmDialog_Content"
      :content_part2="ConfirmDialog_Contentpart_two" :run_name="ConfirmDialog_RunName"
      :items="ConfirmDialog_Items"
      :type="ConfirmType"
      :btn_label="ConfirmDialog_btn_label" :color="ConfirmDialog_btn_color" v-model="showConfirmDialog"
      @close="handleCloseClick" @handleConfirmClick="handleConfirmBtnClick" />
  </v-container>
</template>

<script>
import RunDiscardDialog from '@/components/TestRuns/RunDiscardDialog.vue';
import RunFilter from '@/components/TestRuns/RunFilter.vue';
import RunHeader from '@/components/TestRuns/RunHeader.vue';
import RunTable from '@/components/TestRuns/RunTable.vue';
import RunForm from './RunForm.vue';
import dayjs from 'dayjs';
import { mapGetters, mapState, createNamespacedHelpers } from 'vuex';
import { runStateMap, runTableHeader } from '@/constants/grid';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import makeMilestonesService from '@/services/api/milestone'
import makePlanService from '@/services/api/plan'
const { mapActions } = createNamespacedHelpers('run');
// const { mapState: mapProjectState, mapMutations, mapActions } = createNamespacedHelpers('run');
export default {
  props: ['RunViewType', 'NewRunForm'], // TODO
  components: {
    RunForm,
    RunTable,
    RunHeader,
    RunFilter,
    RunDiscardDialog,
  },
  data() {
    return {
      appliedFilters: null,
      selectedRun: {},
      selectedStatus: Object.values(runStateMap)[0],
      savingInProgress: false,
      headers: runTableHeader,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      expanded: [],
      searchFilter: '',
      menuOpen: false,
      showFormDialog: false,
      openedRow: undefined,
      loading: false,
      checked: false,
      isColumnFilter: false,
      selecteditems: [],
      itemKey: 'uid',
      filter: 'ongoing',
      options: ['ongoing', 'archived'],
      clearSelection: false,
      isFilter: false,
      showConfirmDialog: false,
      ConfirmDialog_Title: '',
      ConfirmDialog_Content: '',
      ConfirmDialog_Contentpart_two: '',
      ConfirmDialog_Items: [],
      ConfirmDialog_btn_label: '',
      ConfirmDialog_btn_color: 'primary',
      ConfirmDialog_RunName: '',
      ConfirmType: '',
      rowClass: () => 'project-item',
    };
  },
  computed: {
    ...mapState('run', ['items']),
    ...mapState('user', ['currentAccount']),
    ...mapGetters({
      currentOrg: 'user/currentAccount',
    }),
    filteredHeaders() {
      const filtered = this.headers.filter((header) => header.isSelected);
      if (filtered.length < 9)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.text != 'Actions');
      return filtered
    },
    filteredRuns() {
      let filtered = this.runs;
      if (this.searchFilter) {
        filtered = filtered.filter((item) => this.matchesFilter(item));
      }
      if (this.isFilter && this.appliedFilters) {
        filtered = filtered.filter((item) => this.matchApplyFilter(item));
      }
      return filtered;
    },
    isDuplicateRunViewType() {
      return this.RunViewType == 'Duplicate';
    },
    isSelected() {
      if (this.selecteditems.length > 0)
        return true
      return false
    },
    hasOneSelectedItem() {
      return this.selecteditems.length === 1;
    },
    runs() {
      let filtered = this.items.filter((item) => (item.customFields.archived === ( this.filter === 'ongoing' ? false : true )));
      return filtered.map((item) => {
        const createdFormat = new Date(item.createdAt);
        const createdAt = `${createdFormat.getFullYear()}-${String(createdFormat.getMonth()+1).padStart(2,'0')}-${new String(createdFormat.getDate()).padStart(2,'0')}`
        
        return {
        ...item,
        processedStatus: this.staticValues(item, runStateMap, 'New'),
        createdAt
        }
      });
    },
    selectedProject() {
      return this.$route.params.key;
    },
    footerProps() {
      return {
        itemsPerPageOptions: [15, 50],
        showCurrentPage: true,
        showFirstLastPage: true,
        hideDefaultFooter: true,
      };
    },
    activeItemCount() {
      return this.items.filter((item) => item.customFields.archived === false).length;
    },
    archivedItemCount() {
      return this.items.filter((item) => item.customFields.archived === true).length;
    },
  },
  watch: {
    currentOrg: 'refreshData',
    filteredRuns(newValue) {
      if (newValue === 'SEARCH_BACKEND') {
        this.searchRuns();
      }
    },
    selectedProject() {
      this.getProjectRun(this.$route.params.handle);
    },
    searchFilter: {
      handler() {
        this.loading = true;
        this.$nextTick(() => {
          this.loading = false;
        });
      },
      immediate: true
    },
  },
  created() {
    let handle = this.currentAccount.handle
    this.getProjectRun(handle);
  },
  methods: {
    ...mapActions(['searchTestRun', 'getAllTestRuns', 'updateTestRun', 'updateTestRuns', 'deleteTestRun', 'deleteTestRuns', 'duplicateTestRun']),
    updateFilter(newFilter) {
      this.filter = newFilter;
    },
    setselected(selecteditems) {
      this.clearSelection = false
      this.selecteditems = selecteditems
    },
    async getMilestones(){

      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const milestoneService = makeMilestonesService(this.$api);
      return await milestoneService.getMilestones(handle, projectKey).then(response => {
        return response.data.milestones
      });
      
    },
    async getTestPlans(){
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const testPlanService = makePlanService(this.$api);
      return await testPlanService.getAllTestPlans(handle, projectKey, 1000,0).then(response => {
        return response.data.plans
      })
    },
    async handleConfirmDialog(type) {
      this.showConfirmDialog = true;
      switch (type) {
        case 'multi_archive':
          this.ConfirmDialog_Title = this.$t('testruns.archive_dialog.title_multi');
          this.ConfirmDialog_Content = this.$t('testruns.archive_dialog.content');
          this.ConfirmDialog_Contentpart_two = '';
          this.ConfirmDialog_btn_label = this.$t('testruns.archive_dialog.btn_label');
          this.ConfirmDialog_btn_color = 'primary';
          this.ConfirmDialog_RunName = '';
          this.ConfirmType = 'multi_archive';
          break;
        case 'multi_unarchive':
          this.ConfirmDialog_Title = this.$t('testruns.unarchive_dialog.title_multi');
          this.ConfirmDialog_Content = this.$t('testruns.unarchive_dialog.content');
          this.ConfirmDialog_Contentpart_two = '';
          this.ConfirmDialog_btn_label = this.$t('testruns.unarchive_dialog.btn_label');
          this.ConfirmDialog_btn_color = 'primary';
          this.ConfirmDialog_RunName = '';
          this.ConfirmType = 'multi_unarchive';
          break;
        case 'multi_delete':
          this.ConfirmDialog_Title = this.$t('testruns.delete_dialog.title');
          this.ConfirmDialog_Content = this.$t('testruns.delete_dialog.content');
          this.ConfirmDialog_Contentpart_two = (this.filter == 'ongoing' ? this.$t('testruns.delete_dialog.content_part2') : '');
          this.ConfirmDialog_btn_label = this.$t('testruns.delete_dialog.btn_label');
          this.ConfirmDialog_btn_color = 'danger';
          this.ConfirmDialog_RunName = '';
          this.ConfirmType = 'multi_delete';
          break;
        case 'archive':
          this.ConfirmDialog_Title = this.$t('testruns.archive_dialog.title');
          this.ConfirmDialog_Content = this.$t('testruns.archive_dialog.content');
          this.ConfirmDialog_Contentpart_two = '';
          this.ConfirmDialog_btn_label = this.$t('testruns.archive_dialog.btn_label');
          this.ConfirmDialog_btn_color = 'primary';
          this.ConfirmDialog_RunName = this.selectedRun.name;
          this.ConfirmType = 'archive';
          break;
        case 'unarchive':
          this.ConfirmDialog_Title = this.$t('testruns.unarchive_dialog.title');
          this.ConfirmDialog_Content = this.$t('testruns.unarchive_dialog.content');
          this.ConfirmDialog_Contentpart_two = '';
          this.ConfirmDialog_btn_label = this.$t('testruns.unarchive_dialog.btn_label');
          this.ConfirmDialog_btn_color = 'primary';
          this.ConfirmDialog_RunName = this.selectedRun.name;
          this.ConfirmType = 'unarchive';
          break;
        case 'delete':
          this.ConfirmDialog_Title = this.$t('testruns.delete_dialog.title');
          this.ConfirmDialog_Content = this.$t('testruns.delete_dialog.content');
          this.ConfirmDialog_Contentpart_two = (this.filter == 'ongoing' ? this.$t('testruns.delete_dialog.content_part2') : '');
          this.ConfirmDialog_btn_label = this.$t('testruns.delete_dialog.btn_label');
          this.ConfirmDialog_btn_color = 'danger';
          this.ConfirmDialog_RunName = '';
          this.ConfirmType = 'delete';
          break;
        case 'multi_milestone':
          this.ConfirmDialog_Title = this.$t('testruns.milestone_dialog.title');
          this.ConfirmDialog_Content = this.$t('testruns.milestone_dialog.content');
          this.ConfirmDialog_Items = await this.getMilestones();
          this.ConfirmDialog_Contentpart_two = '';
          this.ConfirmDialog_btn_label = this.$t('testruns.milestone_dialog.btn_label');
          this.ConfirmDialog_RunName = '';
          this.ConfirmType = 'multi_milestone';
          this.ConfirmDialog_btn_color = 'primary';
          break;
          case 'multi_testplan': 
            this.ConfirmDialog_Title = this.$t('testruns.testplan_dialog.title');
            this.ConfirmDialog_Content = this.$t('testruns.testplan_dialog.content');
            this.ConfirmDialog_Items = await this.getTestPlans();
            this.ConfirmDialog_Contentpart_two = '';
            this.ConfirmDialog_btn_label = this.$t('testruns.testplan_dialog.btn_label');
            this.ConfirmDialog_RunName = '';
            this.ConfirmType = 'multi_testplan';
            this.ConfirmDialog_btn_color = 'primary';
          break;

        
      }
    },
    handleConfirmBtnClick(type,items) {
      switch (type) {
        case 'multi_archive':
          this.handleConfirmArchiveClick(true);
          break;
        case 'multi_unarchive':
          this.handleConfirmArchiveClick(false);
          break;
        case 'multi_delete':
          this.handleConfirmDeleteMultiClick();
          break;
        case 'archive':
          this.handleConfirmOneArchiveClick(true);
          break;
        case 'unarchive':
          this.handleConfirmOneArchiveClick(false);
          break;
        case 'delete':
          this.handleConfirmDeleteClick();
          break;
        case 'multi_milestone':
          this.handleConfirmMilestoneClick(items)
          break;
        case 'multi_testplan':
          this.handleConfirmTestPlanClick(items)
        break;
      }
    },
    handleCloseClick() {
      this.showConfirmDialog = false;
      this.selectedRun = [];
      this.selecteditems = [];
      this.ConfirmDialog_Items = [];
      this.clearSelection = true;
    },
    confirmArchiveRun(item) {
      this.selectedRun = item;
      this.handleConfirmDialog('archive')
    },
    confirmUnArchiveRun(item) {
      this.selectedRun = item;
      this.handleConfirmDialog('unarchive')
    },
    confirmDeleteRun(item) {
      this.selectedRun = item;
      this.handleConfirmDialog('delete')
    },
    editItem(item) {
      this.selectedRun = item;
      this.$router.push({
        name: 'TestRunEdit',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          id: item.uid,
          CustomItem: item, // TODO - don't pass items via the router
        },
      });
    },
    // Updates a test run
    handleConfirmOneArchiveClick(status) {
      const payload = {
        customFields: {
          ...this.selectedRun.customFields,
          archived: status
        },
        externalId: this.selectedRun.externalId,
        source: this.selectedRun.source,
        name: this.selectedRun.name
      };
      this.updateTestRun({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        uid: this.selectedRun.uid,
        orgUid: '1',
        payload: payload
      }).then(() => {
        showSuccessToast(this.$swal, this.$t("projects.archive_project_success"));
        this.refreshData()
      }).catch(error => {
        showErrorToast(this.$swal, this.$t("projects.archive_project_failed"));
      });
      this.handleCloseClick()
    },
    // Updates multi test run
    handleConfirmArchiveClick(status) {
      var runs = []
      this.selecteditems.map((item) => {
        var run = {
          customFields: {
            ...item.customFields,
            archived: status
          },
          externalId: item.externalId,
          source: item.source,
          name: item.name,
          uid: item.uid
        };
        runs = [...runs, run]
      })
      this.updateTestRuns({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        uid: this.selectedRun.uid,
        payload: { runs: runs }
      }).then(() => {
        showSuccessToast(this.$swal, this.$t("projects.archive_project_success"));
        this.refreshData()
      }).catch(error => {
        showErrorToast(this.$swal, this.$t("projects.archive_project_failed"));
      });
      this.handleCloseClick()
    },
    // Delete a test run
    handleConfirmDeleteClick() {
      this.deleteTestRun({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        uid: this.selectedRun.uid,
        perPage: 9999,
        currentPage: 0,
      }).then(() => {
        showSuccessToast(this.$swal, this.$t("Delete 1 Test run"));
        this.refreshData()
      }).catch(error => {
        showErrorToast(this.$swal, this.$t("projects.archive_project_failed"));
      });
      this.handleCloseClick()
    },
    async handleConfirmMilestoneClick(items){
        const handle = this.$route.params.handle;
        const projectKey = this.$route.params.key;
        const milestoneService = makeMilestonesService(this.$api)
        const runIds = this.selecteditems.map(item => item.uid) 
        const payload = {
          runIds
        }
        if(items.length)
          items.forEach(async element => {
            await milestoneService.addRunsToMilestone(handle, projectKey, element, payload).then(response => {
              showSuccessToast(this.$swal, this.$t("testruns.milestone_dialog.success"));
            }).catch(() => {
              showErrorToast(this.$swal, this.$t("testruns.milestone_dialog.failed"));
            })
          })
        this.handleCloseClick()
        
      },
      async handleConfirmTestPlanClick(items){
        const handle = this.$route.params.handle;
        const projectKey = this.$route.params.key;
        const testPlanService = makePlanService(this.$api);
        const runIds = this.selecteditems.map(item => item.uid) 
        const payload = {
          runs: runIds
        }

        if(items.length)
          items.forEach(async element => {
            await testPlanService.addRunsToTestPlan(handle, projectKey, element, payload).then(response => {
              showSuccessToast(this.$swal, this.$t("testruns.testplan_dialog.success"));
            }).catch(() => {
              showErrorToast(this.$swal, this.$t("testruns.testplan_dialog.failed"));
            })
          })
        this.handleCloseClick();
      },
    // Delete multi test run
    handleConfirmDeleteMultiClick() {
      var runIds = []
      this.selecteditems.map((item) => {
        runIds = [...runIds, item.uid]
      })
      this.deleteTestRuns({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        payload: { runIds: runIds },
      }).then(() => {
        showSuccessToast(this.$swal, this.$t("Delete 1 Test run"));
        this.refreshData()
      }).catch(error => {
        showErrorToast(this.$swal, this.$t("projects.archive_project_failed"));
      });
      this.handleCloseClick()
    },
    applyFilters(filters) {
      if (filters) {
        this.appliedFilters = filters;
        this.isFilter = true;
      } else {
        this.isFilter = false;
      }
      this.refreshData()
    },
    matchApplyFilter(item) {
      if (!this.appliedFilters?.panel_priority.includes(item.customFields.priority))
        return false;
      if (!this.appliedFilters.panel_status.includes(item.customFields.status))
        return false
      if (!this.appliedFilters?.panel_milestone.includes(item.customFields.milestone) && !this.appliedFilters?.panel_milestone.includes('None'))
        return false
      if (!this.appliedFilters?.panel_tag.includes(item.customFields.tags) && !this.appliedFilters?.panel_tag.includes('none'))
        return false
      if (this.appliedFilters?.testcases_range[0] > item.customFields.case_count || this.appliedFilters?.testcases_range[1] < item.customFields.case_count)
        return false
      if (this.appliedFilters?.progressRange[0] > item.customFields.progress || this.appliedFilters?.progressRange[1] < item.customFields.progress)
        return false
      if (this.appliedFilters?.dateRange.start > item.createdAt || this.appliedFilters?.dateRange.end < item.createdAt)
        return false
      return true
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      return item.name.toLowerCase().includes(lowerCaseFilter)
    },
    staticValues(item, obj, defultValue) {
      return obj[item.customFields.state] || obj[defultValue];
    },
    // Refreshes all data
    async refreshData() {
      let handle = this.currentAccount.handle;
      this.getProjectRun(handle);
    },
    deleteTestCase(uid) {
      this.$store.dispatch(
        'run/deleteTestRun',
        {
          swal: this.$swal,
          uid,
          handle: this.$route.params.handle,
          projectKey: this.$route.params.key,
          perPage: this.perPage,
          currentPage: this.currentPage,
        },
        { root: true }
      );
    },
    // Fetches test runs within the selected project
    getProjectRun(handle) {
      this.getAllTestRuns({ handle: handle, projectKey: this.$route.params.key, perPage: 99999, currentPage: 0 });
    },
    // Converts timestamp to local time
    convertToLocal(timestamp) {
      return dayjs(timestamp).format('ddd, MMM DD hh:mm A');
    },
    // Determines the type of test
    determineType(test) {
      return test.source === 'pinata' ? 'Exploratory' : 'Manual';
    },
    // Searches for test runs
    async searchRuns() {
      if (this.searchFilter) {
        await this.$store.dispatch('run/searchTestRun', {
          handle: this.$route.params.handle,
          projectKey: this.$route.params.key,
          text: this.searchFilter,
          perPage: this.perPage,
          currentPage: this.currentPage,
        });
      } else {
        this.refreshData();
      }
    },
    handleColumnReset() {
      this.headers.map((header) => { header.isSelected = true })
    },
    async onClickDuplicate(){
      if(this.isDuplicateRunViewType && this.hasOneSelectedItem){
        const item = this.selecteditems[0];
        await this.duplicateTestRun(item);
        this.$router.push({
          name: 'RunAddCase',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
            CustomCases: item?.customFields?.testcases 
          }
        });
      }
    },
    // handle row edit
    handleRowEdit(item) {
      this.selectedRun = item;
      this.showFormDialog = true;
    },
    // Handles row click
    async handleRowClick(item) {
      if (this.RunViewType == 'Duplicate') {

        await this.duplicateTestRun(item);
        this.$router.push({
          name: 'RunAddCase',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
            CustomCases: item.customFields.testcases // TODO - don't pass via router
          }
        });
        
      }
      else {
        this.$router.push({
          name: 'TestRunCaseEdit',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
            id: item.uid,
            CustomCases: item.customFields.testcases, // TODO don't pass via router
            progress: item.customFields.progress,
            name: item.name
          }
        });
      }
    },
  },
};
</script>
<style>
.custom-runtable {
  color: #344054 !important;
}

.custom-color-0c111d {
  color: #0C111D !important;
}

.custom-color-d0d5dd {
  color: #D0D5DD !important;
}

.custom-color-061AAE {
  color: #061AAE !important;
}
.custom-menu-item {
  min-height: 36px;
  height: 36px;
  max-height: 36px;
  align-items: center;
}

.custom-text-12 {
  font-size: 12px;
}

.custom-text-14 {
  font-size: 14px;
}

.h-full {
  height: 100%;
  min-height: 100%;
}
.custom-swal-popup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.custom-swal-title {
  text-align: left;
  width: 100%;
}
.custom-swal-content {
  text-align: left;
  width: 100%;
}
.custom-swal-actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.custom-swal-confirm-button,
.custom-swal-cancel-button {
  margin-left: 10px;
}
</style>
